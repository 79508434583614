import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Form, Input, Button,Spin,Card,Avatar,Anchor,BackTop,Affix } from 'antd';
import { UserOutlined,DropboxOutlined,LockOutlined, LoadingOutlined,RightCircleFilled, PhoneFilled, PhoneOutlined, MessageOutlined, MailOutlined, BankOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'
import * as actions from '../store/actions/auth'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import * as serverconfig from './serverconn'
import { Layout, Menu, Breadcrumb,Carousel,Tabs,Collapse,Image } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import logo from '../assets/Listlist-01.png';
import logo2 from '../assets/eatswallpaper_two.png';
import MessageBox from './MessageCard'
import MessageBox2 from './MessageCard2'
//import { ReactComponent as SvIcon } from '../assets/banner1.svg'; // Import the SVG file


var CryptoJS = require("crypto-js");

const { Header, Content, Footer } = Layout;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Meta } = Card;

const style = {
  height: 40,
  width: 40,
  lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#2F54EB',
  color: '#fff',
  textAlign: 'center',
  fontSize: 14,
};



const About = (props) =>{
  const [datarequested, setdatarequested] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  // Function to check if the screen size is mobile
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
  };


  useEffect(() => {
    setdatarequested(false)

    // Check initial screen size
    checkIsMobile();


    // Add event listener for screen resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  

    if(datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
            <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
            </Spin> 
       </div>
      )
    }else{

      return (
        <div className="animated fadeIn">
          <MessageBox content="Hello, world!" />
          <br></br>
          <br></br>

          <div style={{display:'flex',alignSelf:'center',justifyContent:'center'}}>
          
          
          {
            isMobile?
            <Image
            src={logo}   
            preview={false} 
           // height={500}
            //width={600}
        >  
        </Image>
        :
        <Image
        src={logo}   
        preview={false} 
        height={400}
        width={550}
          >  
          </Image>

          }



          </div>

          <br></br>
          <MessageBox2 />

          <br></br>
          <p style={{display:'flex',fontWeight:'bold',width:'100%',justifyContent:'center',alignSelf:'center'}}>

          <Image
              src={logo2}   
              preview={false} 
          ></Image>
          </p>

       </div>
       );
    }

  
}

const WrappedAbout= About;

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }

}

export default withRouter (connect(mapStateToProps, mapDispatchToProps)(WrappedAbout));
