import React from 'react';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom'

const content = (
  <div>

    <p style={{color:'black'}}>
    <NavLink to={`#`}>eats.biz</NavLink> is more than just a Platform provider. We are your Partner
      in growing your business and reaching more customers. Partner
      with <NavLink to={`#`}>eats.biz</NavLink> today and discover the benefits of having your
      own online ordering and delivery solution.
    </p>
    
  </div>
);

const MessageBox2 = () => {
  return (
      <div style={styles.container}>
        <div style={styles.message}>{content}</div>
        <div style={styles.triangle}></div>
      </div>
  );
};

const styles = {
    container: {
      position: 'relative',
      display: 'inline-block',
    },
    message: {
     // backgroundColor: 'lightgrey',
      padding: '16px',
      borderRadius: '20px',
      cursor: 'pointer',

    },
    triangle: {
      position: 'absolute',
      width: 0,
      height: 0,
      //borderTop: '15px solid lightgrey',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      bottom: '-8px',
      left: '10%', // Adjusted to be slightly to the right
      transform: 'translateX(-50%)', // Centering horizontally
    },
    popoverContent: {
      borderRadius: '10px',
      padding: '16px',
    },




    
  };
  

export default MessageBox2;
