import React from "react";
// import { handleGoogleLogin } from "../../services/AuthService";
import GoogleLogin from "./ GoogleLogin";
import axios from "axios";
import * as serverconfig from "./serverconn";


const SocialLogin = () => {
  
  const onGoogleSignIn = async (res) => {
    const { credential } = res;
    const googleData = { accessToken: credential };
    console.log(googleData, "google data")

    var accessToken=googleData.accessToken

    //hit end point
    let form_data = new FormData();
    form_data.append("client_id", '305386167549-17na2dptl565eu4mujf1jpgk3le6ercn.apps.googleusercontent.com');
    form_data.append("token", googleData.accessToken);

    axios
      .post(
        serverconfig.backendserverurl + `/customqueries/google_token_verifier`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res.data)
      });

      return accessToken

  
  };
  return (
    <div className="social-login">
      <GoogleLogin onGoogleSignIn={onGoogleSignIn} text="Google" />
      
    </div>
  );
};
export default SocialLogin;
