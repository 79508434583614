import React, { useState, useEffect, lazy } from "react";
import axios from "axios";
import { Slider, Statistic, Tabs, Modal,Result } from "antd";
import {
  ArrowUpOutlined,
  MinusCircleFilled,
  ToolOutlined,
  ArrowDownOutlined,
  FileFilled,
  WalletFilled,
  SearchOutlined,
  FundViewOutlined,
  UserOutlined,
  AccountBookOutlined,
  CloseOutlined,
  CalculatorFilled,
  SwapOutlined,
  GroupOutlined,
  AccountBookFilled,
  DollarCircleOutlined,
  EditFilled,
  MessageFilled,
  DollarOutlined,
  CarFilled,
  LoadingOutlined,
  PlusCircleFilled,
  DeleteOutlined,
  PhoneFilled

} from "@ant-design/icons";
import moment from "moment";
import { Line, Pie, Bar } from "react-chartjs-2";
import { withGetScreen } from "react-getscreen";
import {
  Tooltip,
  Popover,
  Table,
  Popconfirm,
  Input,
  Image,
  Button,
  Collapse,
  Card,
  Avatar,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  InputNumber,
  message,
  Spin,
} from "antd";
import { Col, Row } from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import * as serverconfig from "./serverconn";
import {
  MdEmail,
  MdHome,
  MdMailOutline,
  MdMap,
  MdMenu,
  MdPhone,
  MdPhoneAndroid,
  MdShoppingCart,
} from "react-icons/md";

import CurrencyFormat from "react-currency-format";
import Highlighter from "react-highlight-words";
import Websocket from "react-websocket";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NavLink } from "react-router-dom";
import * as primarycolor from "./primarycolor";
import logo from "../assets/eatslogo.png";
import RestaurantsMenuDetails from "./RestaurantsMenuDetails";
import * as reactstrp from 'reactstrap';
import GoogleLogin from "./ GoogleLogin";
import RestaurantHome from "./RestaurantsHome";
import MyContext from './MyContext';

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = {
  background: "#0092ff",
  padding: "8px 0",
  borderRadius: "7px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const dateFormat = "DD/MM/YYYY";
const yeardateFormat = "YYYY";
const monthdateFormat = "MM/YYYY";

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;
const { Search } = Input;

var bizuserid = "";
var sacco = "";
var token = "";
const t_time="HH:mm:ss"


class RestaurantsHome_Multibranch extends React.Component {
  state = {
    datarequested: true,
    contine_withgoogle: false,
    restaurant_obj: {},
    restaurants: [],
    menu_details_visible: false,
    selected_menu_object: {},

    current_cart_list: [],
    current_tab:'',

    //now proceed to upload section
    mode_of_payment:'',
    additional_addressinfo:'',
    notes:'',
    area:'',
    delivery_price:0,
    hub_zones:[],
    telephone_contact:'',
    datasubmittedsuccessfully:false,
    
  };

  callback = (key) => {
    console.log(key);
    console.log(this.state.yearlymonthlysales);
  };

  componentDidMount() {
    this.comp_loaded()
  }

  //capitalize fistrt letter
  capitalizeFirstLetter=(word)=> {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }


  comp_loaded=()=>{
    const { setshowtop_items } = this.context;
    // Update the context value
    setshowtop_items(false);


     //ned to eplore
      this.setState({restaurant_obj:this.props.restaurant})

      var res_data = this.props.restaurant

     //get mult resta branches
    let form_data = new FormData();
    form_data.append("backend", serverconfig.backendserverurl);
    form_data.append("multi_res_id", res_data.id);
    axios
      .post(
        serverconfig.backendserverurl +
          `/customqueries/get_multi_res_branches`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        //set current tabe
        this.setState({current_tab:JSON.parse(res.data.report).key})

        this.setState({
          restaurants: JSON.parse(res.data.report),
        });

        this.setState({ datarequested: false });

      });


      //set data
      this.setState({datasubmittedsuccessfully:false})
  }


    //calculate totla
    calculateTotal=()=>{
      var total=0
      this.state.current_cart_list.map((item)=>{
        total+=Number(item.price)
      })
      
      return total
    }


  render() {
    
    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
          </Spin>
        </div>
      );
    } else {


      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title="Order successfully submitted."
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  this.comp_loaded()
                }}>Finish</Button>,
              ]}
              />

              </Card>
          </div>
        )    

      }else{

        return (
          <div className="animated fadeIn"  >
           
              <div style={{backgroundColor:'black'}}>

              <div style={{
              display:'flex',
              flexDirection:'column',
              //backgroundImage: `url(${this.state.restaurant_obj.restaurant_banner})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              //height: '300px', // Adjust height as needed
              borderBottomLeftRadius: '60px',
              borderBottomRightRadius: '60px',
              justifyContent:'center',
              alignItems:'center'
        
              }}>
               
                {/**image in banner */}
               
                {
                  this.state.restaurant_obj.logo===""?
                  null:
                  <div style={{display:'flex',height:'100px',
                  width:'100px',margin:10,marginTop:20,
                  borderRadius:'50%',backgroundColor:'white',
                  alignItems:'center',
                  justifyContent:'center'}}>
                   <Image
                    src={this.state.restaurant_obj.logo}   
                    preview={false} 
                    width={70}
                    height={70}
   
                   ></Image>
                  </div>
                }

              <p style={{fontSize:25,fontWeight:'bolder',color:'white',margin:10}}>{this.state.restaurant_obj.name.toUpperCase()}</p>
              <p style={{fontSize:20,fontWeight:'bold',color:'lightgrey',margin:10}}>{this.state.restaurant_obj.about}</p>

              <p style={{fontSize:22,fontWeight:'bold',color:'white',margin:10}}>Select branch </p>

              {
                  this.state.restaurants.length>0?
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                
                    {
                        this.state.restaurants.map((rest)=>{
                          return (

                            <Link to={`/${rest.online_order_link}`}>
                            <Button type="primary" style={{margin:3}}>
                              {this.capitalizeFirstLetter(rest.area_name)}
                            </Button>
                            
                            </Link>
                        
                          )
                        })
                  }

                  </div>

                :
                null
                }


              <p style={{fontSize:18,fontWeight:'bold',color:'white',margin:2}}>{this.state.restaurant_obj.email}</p>
              <p style={{fontSize:18,fontWeight:'bold',color:'white',margin:2}}>{this.state.restaurant_obj.phone}</p>



      </div>

      </div>
      <br></br>





          </div>
        );

      }


    }
  }
}

RestaurantsHome_Multibranch.contextType = MyContext;

export default RestaurantsHome_Multibranch;
