import React from 'react';
import MyContext from './MyContext'; // Correct way to import

class MyProvider extends React.Component {
  state = {
    showtop_items: true
  }

  render() {
    return (
      <MyContext.Provider value={{
        state: this.state,
        setshowtop_items: (value) => {
          // Set status
          this.setState({ showtop_items: value });
        }
      }}>
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export default MyProvider;
