import React,{ useRef } from "react";
import useScript from "./Customhooks/ useScript";
export default function GoogleLogin({
  onGoogleSignIn = () => {},
  onGoogleLogout = () => {},
  text = "signin_with",
}) {
  const googleSignInButton = useRef(null);

  useScript("https://accounts.google.com/gsi/client", () => {
    window.google.accounts.id.initialize({
      client_id: '305386167549-17na2dptl565eu4mujf1jpgk3le6ercn.apps.googleusercontent.com',
      callback: onGoogleSignIn,
    });
    window.google.accounts.id.renderButton(
      googleSignInButton.current,
      {
        theme: "filled_blue",
        size: "large",
        text,
        width: "250",
        prompt_parent_id: googleSignInButton.current, // specify the button's container
      } // customization attributes
    );
  });
  const handleGoogleLogout = () => {
    if (window.google && window.google.accounts) {
      window.google.accounts.id.prompt();
    }
    onGoogleLogout(); // Call the logout handler from the parent component
  };
  return <div ref={googleSignInButton}></div>;
}
