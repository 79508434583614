export const backendserverurl='https://1-data-biz000789.eats.biz'

//https://1-be-pifs01-mta-app1.pitech.co.ug
//127.0.0.1:8000
//138.197.8.179
//http://138.197.8.179:10225/
//104.131.54.129:10225

// https://1-data-biz000789.eats.biz

//http://143.198.52.143/:10225

//https://1-data-biz000789.eats.biz/
//server 64.23.246.250:7879