import React from 'react';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom'
import { primarycolor } from './primarycolor';

const content = (
  <div>

    <p style={{color:'black',display:'flex',justifyContent:'center',fontSize:16}}>
      +256 701 764128
    </p>
    
    <p style={{color:'black',display:'flex',justifyContent:'center',fontSize:16}}>
      info@eats.biz 
    </p>

    <p style={{color:primarycolor,display:'flex',justifyContent:'center',fontSize:16}}>
      Bukoto,Kampala Uganda
    </p>
    

  </div>
);

const MessageBox3 = () => {
  return (
   
      <div style={styles.container}>
        <div style={styles.message}>{content}</div>
        <div style={styles.triangle}></div>
      </div>
  );
};

const styles = {
    container: {
      position: 'relative',
      display: 'inline-block',
    },
    message: {
      //backgroundColor: 'lightgrey',
      padding: '16px',
      borderRadius: '20px',
      cursor: 'pointer',

    },
    triangle: {
      position: 'absolute',
      width: 0,
      height: 0,
      //borderTop: '15px solid lightgrey',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      bottom: '-8px',
      left: '10%', // Adjusted to be slightly to the right
      transform: 'translateX(-50%)', // Centering horizontally
    },
    popoverContent: {
      borderRadius: '10px',
      padding: '16px',
    },




    
  };
  

export default MessageBox3;
