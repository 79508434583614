import React, { Fragment } from 'react';
import { Form, Input, Button,Spin,Select,DatePicker,Image,Switch, Card, message, Divider, Row } from 'antd';
import axios from 'axios'
import * as serverconfig from './serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import MessageBox3 from './MessageCard3';
//import PhoneInput from 'react-phone-number-input'
import GoogleLogin from "./ GoogleLogin";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';



var CryptoJS = require("crypto-js");
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var username=''

const { TextArea } = Input;

class SignUpForm extends React.Component {

  state = {
    email:'' ,  
    message:'',
    name:'',
    phone:'',
    datarequested:false,

    signup_with:'OtherEmail',
    signup_email:'',
    signup_phone:'',
    password1:'',
    password2:'',

    signin_with:'OtherEmail',
    signin_password:'',
    signin_phone:'',
    signin_email:'',

    signup_visible:false,
  
  }


  componentDidMount(){

    

  }


  //google signup
   onGoogleSignIn = async (res) => {
    const { credential } = res;
    const googleData = { accessToken: credential };
    console.log(googleData, "google data");

    var accessToken = googleData.accessToken;

    //hit end point
    let form_data = new FormData();
    form_data.append(
      "client_id",
      "305386167549-17na2dptl565eu4mujf1jpgk3le6ercn.apps.googleusercontent.com"
    );
    form_data.append("token", googleData.accessToken);

    axios
      .post(
        serverconfig.backendserverurl + `/customqueries/google_token_verifier`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data.userid != "") {
          //setup profile
          //setuser_profile(res.data)
          message.info("Google signup successful")

          localStorage.setItem("g_user", res.data.userid);
          //setcontine_withgoogle(false)

          //load_initdata()
          

        }else{
          message.error("Google Signup failed")
          //load_initdata()

        }

      });
  };




  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('email', this.state.email);
    form_data.append('message', this.state.message);
    form_data.append('phone', this.state.phone);
    form_data.append('name', this.state.name);

    axios.post(serverconfig.backendserverurl+'/customqueries/send_messagenote', form_data, {
      emailers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{
    message.info(res.data.message)
    this.setState({datarequested:false})
    window.location.reload(false)
  }
  )
  .catch(error => console.log(error))

  }


  isValidEmail=(email)=> {
    // Regular expression pattern for validating email addresses
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )



      
    }else{
      return (
        <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>

             <div style={{justifyContent:'center',display:'flex'}}>
             <GoogleLogin onGoogleSignIn={this.onGoogleSignIn} text="Google" />
             </div> 

            <Divider></Divider>   
            <h5 
             style={{display:'flex',justifyContent:'center',alignSelf:'center',color:'#333'}}
            >Phone/Email Sign In.
            </h5>


             <Form>

            <FormItem  label="Sign In With">
            <Select 
            placeholder="Sign In With" 
            value={this.state.signin_with}
            onChange={(val)=>{this.setState({signin_with:val})}} 
            showSearch
            optionFilterProp="children"
            >
              <Option value={'OtherEmail'}>{'Other Email'}</Option>
              <Option value={'Phone'}>{'Phone'}</Option>
            </Select>
            </FormItem>

            {
              this.state.signin_with==="Phone"?
              <FormItem label= {"Phone"} >
              <PhoneInput
                country="ug"
                placeholder="Enter Phone"
                value={this.state.signin_phone}
                onChange={(val)=>{this.setState({signin_phone:val})}}
                enableSearch={true}
                />
              </FormItem> 
              :
              null
            }


              {
                   this.state.signin_with==="OtherEmail"?

                  <Form.Item
                    label="Email"
                  >
                    <Input type="mail"
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                      value={this.state.signin_email}
                      onChange={(val)=>{this.setState({signin_email:val.target.value})}}  />
        
                  </Form.Item>:
                  null
                  }


                <Form.Item label="Password" >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  value={this.state.signin_password}
                  onChange={(val)=>{this.setState({signin_password:val.target.value})}}
                  />
                </Form.Item>

               
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                <Button type='primary' 
                  onClick={()=>{
                    if (this.state.signin_phone==="" && this.state.signin_with==="Phone"){
                      message.error("Phone missing ")
  
                    }else if (this.state.signin_email==="" && this.state.signin_with==="OtherEmail"){
                      message.error("Email missing ")
  
                    }else if (this.isValidEmail(this.state.signin_email)===false && this.state.signin_with==="OtherEmail"){
                      message.error("Invalid email ")
                      }
                    else{
  
                      let form_data_o1 = new FormData();
                      form_data_o1.append("signin_phone", this.state.signin_phone);
                      form_data_o1.append("signin_email", this.state.signin_email);
                      form_data_o1.append("signin_with", this.state.signin_with);
                      form_data_o1.append("password", this.state.signin_password);
    
                      axios.post(serverconfig.backendserverurl+'/customqueries/clientlogin',
                      form_data_o1, {
                      headers: {
                        "content-type": "multipart/form-data",
                      },
                    })
              
                    .then(res => {
                      message.info(res.data.message)

                      if (res.data.message==="Successful LogIn"){
                        localStorage.setItem("g_user", res.data.resonse_obj.googleId);
                        //reload home
                        this.props.onRefresh()
                      }  

                      this.setState({signin_phone:''})
                      this.setState({signin_email:''})
                      this.setState({signin_password:''})
       
    
                    })
                    .catch(error => console.log(error))
                
                    }


                }}>
                  Login
                </Button>
                </div>

             </Form>


             <h4 
             style={{display:'flex',justifyContent:'center',alignSelf:'center',color:'blue'}}
              onClick={()=>{
                this.setState({signup_visible:!this.state.signup_visible})
             }}>No Account?  Create Account.</h4>


             {
              this.state.signup_visible===true?
              <Card>
              <br></br>

              <Form
                onFinish={(event) =>
                {

                  if (this.state.signup_phone==="" && this.state.signup_with==="Phone"){
                    message.error("Phone missing ")

                  }else if (this.state.signup_email==="" && this.state.signup_with==="OtherEmail"){
                    message.error("Email missing ")

                  }else if (this.isValidEmail(this.state.signup_email)===false && this.state.signup_with==="OtherEmail"){
                    message.error("Invalid email ")

                  }
                  else{

                    let form_data_o1 = new FormData();
                    form_data_o1.append("signup_phone", this.state.signup_phone);
                    form_data_o1.append("signup_email", this.state.signup_email);
                    form_data_o1.append("signup_with", this.state.signup_with);
  
                    form_data_o1.append("password1", this.state.password1);
                    form_data_o1.append("password2", this.state.password2);
  
  
                    axios.post(serverconfig.backendserverurl+'/customqueries/createclientsign_up',
                    form_data_o1, {
                    headers: {
                      "content-type": "multipart/form-data",
                    },
                  })
            
                  .then(res => {
                    message.error(res.data.message)
                    this.setState({signup_phone:''})
                    this.setState({signup_email:''})
                    this.setState({password1:''})
                    this.setState({password2:''})
  
  
  
                    //reload home
                   this.props.onRefresh()
                  })
                  .catch(error => console.log(error))
              
                  }


                }}
                >
                  <FormItem  label="Sign Up With">
                  <Select 
                  placeholder="Sign Up With" 
                  value={this.state.signup_with}
                  onChange={(val)=>{this.setState({signup_with:val})}} 
                  showSearch
                  optionFilterProp="children"
                  >
                    <Option value={'OtherEmail'}>{'Other Email'}</Option>
                    <Option value={'Phone'}>{'Phone'}</Option>
                  </Select>
                  </FormItem>

                  {
                      this.state.signup_with==="Phone"?
                      <FormItem label= {"Phone"} >
                      <PhoneInput
                        country="ug"
                        placeholder="Enter Phone"
                        value={this.state.signup_phone}
                        onChange={(val)=>{this.setState({signup_phone:val})}}/>
                      </FormItem> 
                      :
                      null
                  }
              


                  {
                   this.state.signup_with==="OtherEmail"?

                  <Form.Item
                    label="Email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}
                  >
                    <Input type="mail"
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                      value={this.state.signup_email}
                      onChange={(val)=>{this.setState({signup_email:val.target.value})}}  />
        
                  </Form.Item>:
                  null
                  }
        
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true, message: 'Please input your password!',
                        },

                      ]}
                      hasFeedback

                    >
                      <Input.Password 
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      value={this.state.password1}
                      onChange={(val)=>{this.setState({password1:val.target.value})}}
                      />
          
          
                    </Form.Item>
    
        
                    <Form.Item
                      name="confirm"
                      label="Confirm Password"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
              
                            return Promise.reject('The two passwords that you entered do not match!');
                          },
                        }),
                      ]}
                    >
                      <Input.Password 
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password" 
                      value={this.state.password2}
                      onChange={(val)=>{this.setState({password2:val.target.value})}}
                      />
          
                    </Form.Item>

                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>

                    <FormItem>
                      <Button  type="primary" htmlType="submit">Sign Up</Button>
                    </FormItem>
                    </div>

                 </Form>

              </Card>
              :
              null
             }




        </div>
      );
    }

  }

}


export default SignUpForm;



