import React from 'react';
import { Popover } from 'antd';
import { NavLink } from 'react-router-dom'

const content = (
  <div>

    <p style={{color:'black'}}>
    With a rich history dating back to 2016, Pink Tie Logistics has positioned
itself as Uganda's premier logistics solution; pioneering food delivery for
companies like Jumia and KFC. With this experience we launched &nbsp;
<a href={`#`}>eats.biz</a> to support Restaurants with care and dedication as our
top priority.    </p>
    <p style={{color:'black'}}>
    Are you tired of paying high commissions to aggregators and would
like to have your own branded WebApp and website that is easy to
use for your online orders? Do you need a reliable delivery service
that handles your orders efficiently and professionally?    </p>
    <p style={{color:'black'}}>
    If you answered yes to any or all of these questions, then <a href={`#`}>eats.biz</a> is the
perfect solution for you. <a href={`#`}>eats.biz</a> is a PaaS for Restaurants that provides
a comprehensive online ordering and delivery solution. With <a href={`#`}>eats.biz</a>,
you can:    
    </p>
    
  </div>
);

const MessageBox = () => {
  return (
   
      <div style={styles.container}>
      <h3 style={{display:'flex',justifyContent:'center',color:'black'}}>About eats.biz</h3>
        <div style={styles.message}>{content}</div>
        <div style={styles.triangle}></div>
      </div>
  );
};

const styles = {
    container: {
      position: 'relative',
      display: 'inline-block',
    },
    message: {
      backgroundColor: 'lightgrey',
      padding: '16px',
      borderRadius: '20px',
      cursor: 'pointer',

    },
    triangle: {
      position: 'absolute',
      width: 0,
      height: 0,
      borderTop: '15px solid lightgrey',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      bottom: '-8px',
      left: '10%', // Adjusted to be slightly to the right
      transform: 'translateX(-50%)', // Centering horizontally
    },
    popoverContent: {
      borderRadius: '10px',
      padding: '16px',
    },


    
  };
  

export default MessageBox;
